import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const ActivityIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      data-cy="activity-icon"
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33252 10.5C8.78023 10.5 8.33252 10.9477 8.33252 11.5C8.33252 12.0523 8.78023 12.5 9.33252 12.5V10.5ZM14.3325 12.5C14.8848 12.5 15.3325 12.0523 15.3325 11.5C15.3325 10.9477 14.8848 10.5 14.3325 10.5V12.5ZM9.33252 13.8333C8.78023 13.8333 8.33252 14.281 8.33252 14.8333C8.33252 15.3856 8.78023 15.8333 9.33252 15.8333V13.8333ZM14.3325 15.8333C14.8848 15.8333 15.3325 15.3856 15.3325 14.8333C15.3325 14.281 14.8848 13.8333 14.3325 13.8333V15.8333ZM12.9108 4.24408L12.2036 4.95113L12.2036 4.95118L12.9108 4.24408ZM17.4226 8.75592L18.1297 8.04887L18.1297 8.04882L17.4226 8.75592ZM9.33252 12.5H14.3325V10.5H9.33252V12.5ZM9.33252 15.8333H14.3325V13.8333H9.33252V15.8333ZM16 18H7.66667V20H16V18ZM7.66667 18C7.29847 18 7 17.7015 7 17.3333H5C5 18.8061 6.19392 20 7.66667 20V18ZM7 17.3333V5.66667H5V17.3333H7ZM7 5.66667C7 5.29848 7.29848 5 7.66667 5V3C6.19391 3 5 4.19391 5 5.66667H7ZM7.66667 5H12.3215V3H7.66667V5ZM12.3215 5C12.2773 5 12.2349 4.98248 12.2036 4.95113L13.6179 3.53702C13.2741 3.19312 12.8077 3 12.3215 3V5ZM12.2036 4.95118L16.7155 9.46303L18.1297 8.04882L13.6179 3.53697L12.2036 4.95118ZM16.7154 9.46298C16.6843 9.43181 16.6667 9.38948 16.6667 9.34517H18.6667C18.6667 8.85886 18.4734 8.39259 18.1297 8.04887L16.7154 9.46298ZM16.6667 9.34517V17.3333H18.6667V9.34517H16.6667ZM16.6667 17.3333C16.6667 17.7015 16.3682 18 16 18V20C17.4728 20 18.6667 18.8061 18.6667 17.3333H16.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ActivityIcon;
